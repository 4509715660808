import * as React from 'react'
import { Link } from 'gatsby'
import CardImage from './common/card-image'
import Block from './common/block'
const ThreeCard = (props: any) => {
  const data = props.data.cards;
  const className = props.className ?  props.className : ''
  return (
    <>
      <Block className={'list-card-block '+ props.background + ' ' + className}>
        <div className='container'>
          <div className='row justify-content-center'>
              {
                data.map((card: any)=>(
                  <div className='col-lg-4' key={card.id}>
                    <Link to={card.link ? card.link : ''}>
                       <CardImage src={card.image}  />
                    </Link>
                  {
                    card.title ? (
                      <Link className='card-title' to={card.link}>{card.title} <span>➜</span></Link> 
                      ) : null
                  }
                  </div>
                ))
              }
              
          </div>
        </div>
      </Block>
      
    </>
  )
}

export default ThreeCard
