import * as React from 'react'
import {Link} from "gatsby"; 
import styled from 'styled-components'
import { breakpointLg, breakpointMd} from '../design/responsive/breakpoint'
import { marginBlock, marginBlockMobile} from '../design/spacing/margin'
const Block = styled.section`
  
    @media (min-width: ${breakpointLg}) {
        padding-bottom: calc(${marginBlock} - 4rem)!important;
        &.p-0 {
            padding-bottom: 0!important;
            .feature-benefit__item {
                margin-bottom: 0;
            }
            
        }
    }
   
    .btn-outline-secondary {
        white-space: nowrap;
    }
    @media (max-width: ${breakpointMd}) {
        & + .heading-block {
            margin-top: 3rem;
        }
    }
    @media (max-width: ${breakpointLg}) {
        & + .title-block {
            margin-top: -.5rem;
        }
    }
    
`;
const Item = styled.div`
    margin-bottom: 4rem;
    @media (max-width: ${breakpointLg}) {
        margin-bottom: 0;
        & +  .feature-benefit__item {
            margin-top: 4rem;
        }
    }
`;
const Buttons = styled.div`
    padding: 3rem 0;
    @media (max-width: ${breakpointMd}) {
        padding: 3rem 0 0;
    }
    
`;
const Image = styled.div`
    &.ratio {
        max-width: 8rem;
    }
`;
const FeatureBenefits = (props: any) => {
    const data = props.data
    const className = props.className ?  props.className : ''
    const spacing = props.noSpacing ? 'p-0' : ''
    return (
        <>
            <Block className={'feature-benefit tpl-block ' + props.background + ' ' + className  + ' ' + spacing}   >
                <div className='container'>
                    <div className='row '>
                        
                        {
                            data.map((feature: any)=>(
                                <Item className='col-lg-4  feature-benefit__item'  key={feature.id}>
                                    <Image className='mb-5 feature-benefit__icon ratio ratio-1x1'>
                                        <img width='80' src={feature.image} alt='Consumer Health & Wellness' />
                                    </Image>
                                    <div className='feature-benefit__des'>
                                        <h4>{feature.title}</h4>
                                        <div>{feature.content}</div>
                                    </div>
                                    {
                                        feature.links ? (
                                            <Buttons className='feature-benefit__links'>
                                                {
                                                feature.links.map((link: any)=>(
                                                    <a href={link.link} className='btn btn-outline-secondary mr-20' key={link.id}>
                                                        {link.text}
                                                    </a>
                                                ))
                                            }
                                              
                                            </Buttons>
                                        ) : null
                                    }  
                                    
                                </Item>
                            ))
                        }
                        
                    </div>
                </div>
            </Block> 
        </>
    )
}

export default FeatureBenefits
