import * as React from "react"
import Layout from "../components/common/layout"
import Hero from "../components/hero"
import Title from "../components/title"
import ThreeCard from "../components/three-card"
import FeatureBenefits from "../components/feature-benefits"
import {  graphql } from "gatsby"
import { Helmet } from 'react-helmet'
import Benefits from "../components/benefits";
const NutritionHealthyGuide = ({ data }: any) => {
    const _data = data.allSampleDataJson?.nodes[0].data || []
    const dataTitle = _data.find((item: any) => item.block === 'title')
    const threeCard = _data.find((item: any) => item.block === 'three-card')
    const hero = _data.find((item: any) => item.block === 'hero')
    const featureBenefits = _data.find((item: any) => item.block === 'feature-benefits')
    const dataBenefits = _data.find((item: any) => item.block === 'benefits')
    return (
      <Layout>
        <Helmet
          title='Nutrition healthy guide'
        />
        <Hero container={true} background="bg-blue-white" data={hero.data[0]}></Hero>
        <Benefits data={dataBenefits.data[0]} background="bg-white"  className="pb-0 pt-0"></Benefits>
        <Title data={dataTitle.data[0]} background="bg-white" ></Title>
        <ThreeCard data={threeCard.data[0]} background="bg-white"></ThreeCard>
        <Title data={dataTitle.data[1]} background="bg-chalk" ></Title>
        <FeatureBenefits data={featureBenefits.data[0].feature_benefits} background="bg-chalk"></FeatureBenefits>
        <Title data={dataTitle.data[2]} background="bg-white" ></Title>
        <div className="form-bblock">
          
          <div className="container">
              <div className="row justify-content-center">
                  <div className="col-md-8 col-lg-4">
                      {/* emeb form here */}
                      <div className="mb-20">
                          <label>First name</label>
                          <input type="text" className="form-control" />
                      </div>
                      <div className="mb-20">
                          <label>Last name</label>
                          <input type="text" className="form-control" />
                      </div>
                      <div className="mb-20">
                          <label>Email</label>
                          <input type="email" className="form-control" />
                      </div>
                      
                      <div className="action-form">
                          <button type="submit" className="btn btn-primary d-block">Submit</button>
                      </div>
                      {/* emeb form here */}
                  </div>
              </div>
              <div className="col-md-12 disclamer">
              Our dietary recommendations are created by a Registered Associate Nutritionist and are in line with the Australian Dietary Guidelines provided by the National Health and Medical Research Council. Please be aware they are guidelines only and are general in nature should not substitute independent professional advice from your qualified health care professional. This should not substitute independent professional advice from your qualified health care professional. If you have any allergies, medical issues or concerns, please consult your doctor or a registered dietitian before making any major changes to your diet. Live Life Get Active and their representatives to the extent permitted by law, do not accept any liability for any injury, loss or damage incurred by use of or reliance on the information provided.
              </div>
          </div>
        </div>
      </Layout>
  )
}

// Step 2: Export a page query
export const query = graphql`
  query {
    allSampleDataJson(filter: {jsonName: {in: ["nutrition-healthy-guide"]}}) {
      nodes {
        jsonName
        data {
          block
          data {
            hero {
              hight_light
              content
              images {
                id
                url
                width
              }
              title
              title_top
            }
            title_data {
              title
              content
              links{
                id
                text
                url
              }
            }
            text_media {
              title
              content
              image
              video {
                thumbnail
                title
                video
              }
              links{
                id
                text
                url
              }
            }
            cards {
              id
              title
              image
              link
            }
            benefits {
              id
              title
              image
            }
            feature_benefits {
              content
              image
              title
              id
            }
            prefooter_data {
              title
              content
              links {
                id
                text
                url
              }
            } 
          }
        }
      }
      }
  }
`
export default NutritionHealthyGuide
